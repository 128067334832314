import store from "src/store/store";
import { withScope, captureMessage } from '@sentry/vue';

import log from "src/services/logger";
const logTag = "sentryWrapper";

export const sendToSentry = (text, contextKey = null, contextData = null) => {

    try {
        withScope(function(scope) {
            // some additional context added by the caller of this function
            if (contextKey) {
                scope.setContext(contextKey, contextData);
            }

            // default game context added to every call
            // @note: sentry automatically removes null values
            const gameData = {
                isAuthenticated : store.getters.isAuthenticated,
                route           : store.getters.route?.fullPath
            };
            scope.setContext("game_data", gameData);

            // default player context added to every call
            const playerData = {
                id              : store.getters.user.id,
                name            : store.getters.user.name,
                teamId          : store.getters.team?.id,
                areaId          : store.getters.currentAreaId,
                focused         : store.getters.currentFocusedCategory?.id,
                item            : store.getters.currentItem?.id,
            };
            scope.setContext("player_data", playerData);

            const playerProgress = {
                score           : store.getters.currentProgress?.score,
                answeredItems   : store.getters.currentProgress?.answered_items_count,
                rank            : store.getters.currentProgress?.rank,
                achievements    : store.getters.currentProgress?.achievements?.achieved_count,
                battles         : `${store.getters.currentProgress?.battles?.won}/`
                    + `${store.getters.currentProgress?.battles?.lost}/`
                    + `${store.getters.currentProgress?.battles?.tie}`,

            };
            scope.setContext("player_progress", playerProgress);

            // log.log(logTag, `Sending to sentry: ${text}. Following: contextData, gameData, playerData, playerProgress`);
            // log.log(logTag, contextData);
            // log.log(logTag, gameData);
            // log.log(logTag, playerData);
            // log.log(logTag, playerProgress);

            captureMessage(text);
        });

    } catch (error) {
        log.log(logTag, `Failed to report to sentry!`);
        log.log(logTag, error);
    }
};
